<template>
    <div class="container-xl" v-if="!$store.getters.isForbidden">
      <div class="card">
        <div class="card-header border-0 justify-content-between">
          <div class="card-title">{{ $route.name }} List</div>
          <div class="flex flex-row mb-2 pr-2 float-right">
            <el-input clearable @clear="searchHandler" style="width: 200px" size="small" type="text" v-model="search_keyword" placeholder="Input search keyword" class="mr-2"/>
            <el-select size="small" v-model="merchant_status" placeholder="Merchant Status" class="mr-2" filterable style="width: 100px">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
            <el-button type="primary" @click="clikCreateMerchant" size="small"><font-awesome-icon icon="plus"/> Create Merchant</el-button>
            <el-button type="primary" @click="exportCSV" size="small">Export All</el-button>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table card-table text-nowrap table-vcenter">
            <thead>
              <tr>
                <th width="30%">Name</th>
                <th width="20%">PIC</th>
                <th width="10%">Telp</th>
                <th width="30%">Address</th>
                <th width="5%">Status</th>
                <!-- <th width="5%">Merchant Apps Status</th> -->
                <th width="10%">#</th>
              </tr>
            </thead>
            <tbody v-loading="isLoading">
              <tr v-for="(item, i) in list" :key="i">
                <td>{{ item.merchant_name }}</td>
                <td>{{ item.merchant_pic }}</td>
                <td class="text-nowrap text-muted">{{ item.merchant_telp }}</td>
                <td class="td-truncate">
                  <div class="text-truncate">
                    {{ item.merchant_address }}
                  </div>
                </td>
                                <td><span class="tag" :class="item.status_color">{{ item.status_str }}</span></td>
                <!-- <td>
                  <span class="tag" :class="item.merchant_app_status_color">{{ item.merchant_app_status_str }}</span>
                </td> -->
                <td>
                  <button class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-2" style="width: 200px">
                    <a class="dropdown-item btn-sm cursor-pointer" @click="clickEditMerchant(item)">
                      <font-awesome-icon class="text-primary" :icon="['fas', 'pen']"/>&nbsp; Edit Merchant
                    </a>
                    <a class="dropdown-item btn-sm cursor-pointer" @click="toggleMerchantStatus(item)">
                      <font-awesome-icon class="text-danger" :icon="['fas', 'power-off']"/>&nbsp; Enable/Disable Merchant
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
        </div>
        <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
          <div class="inline-table">
            <b-pagination
              class="table-cell"
              @change="pageChangeHandler"
              :per-page="per_page"
              :limit="5"
              :total-rows="total_rows"
              v-model="page"
            />
          </div>
        </div>
        <b-modal size="lg" v-model="showModalMerchant" :title="modalTitle" hide-footer @hide="closeModalMerchant">
          <b-form @submit.prevent="onSubmitMerchant" @reset="closeModalMerchant">
            <b-form-group label="Merchant Name">
              <b-input
                v-model="v$.form.merchant_name.$model"
                placeholder="Input merchant name"
                type="text"/>
              <b-form-invalid-feedback :state="!v$.form.merchant_name.$invalid" v-if="v$.form.merchant_name.$errors[0]">
                <span> {{ v$.form.merchant_name.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Merchant Category">
              <el-select v-model="v$.form.merchant_category_id.$model" class="w-full">
                <el-option v-for="(item) in categoryList" :key="item.category_id" :value="item.category_id" :label="item.category_name" />
              </el-select>
              <b-form-invalid-feedback :state="!v$.form.merchant_name.$invalid" v-if="v$.form.merchant_name.$errors[0]">
                <span> {{ v$.form.merchant_name.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Merchant Address">
              <b-form-textarea
                v-model="v$.form.merchant_address.$model"
                placeholder="Input merchant address"
                rows="3"
                type="textarea"/>
              <b-form-invalid-feedback :state="!v$.form.merchant_address.$invalid" v-if="v$.form.merchant_address.$errors[0]">
                <span> {{ v$.form.merchant_address.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Merchant Description">
              <quill-editor v-model="form.merchant_description"
                ref="merchantDescription"
                :options="editorOption">
              </quill-editor>
              <b-form-invalid-feedback :state="!v$.form.merchant_description.$invalid" v-if="v$.form.merchant_description.$errors[0]">
                <span> {{ v$.form.merchant_description.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Merchant PIC Name">
              <b-input
                v-model="v$.form.merchant_pic.$model"
                placeholder="Input PIC name"
                type="text"/>
              <b-form-invalid-feedback :state="!v$.form.merchant_pic.$invalid" v-if="v$.form.merchant_pic.$errors[0]">
                <span> {{ v$.form.merchant_pic.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Merchant Phone Number">
              <b-input
                v-model="v$.form.merchant_telp.$model"
                placeholder="Input PIC name"
                type="text"/>
              <b-form-invalid-feedback :state="!v$.form.merchant_telp.$invalid" v-if="v$.form.merchant_telp.$errors[0]">
                <span> {{ v$.form.merchant_telp.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Banner">
              <b-form-file
                v-model="selected_file.merchant_banner"
                :state="Boolean(selected_file.merchant_banner)"
                class="mt-2 border-1 p-2"
                accept=".png, .jpg, .jpeg"
                @input="onChangeFile($event, 'merchant_banner')"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <b-form-invalid-feedback :state="!v$.form.merchant_banner.$invalid" v-if="v$.form.merchant_banner.$errors[0]">
                <span> {{ v$.form.merchant_banner.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
              <img ref="current_merchant_banner" :src="form.current_merchant_banner"/>
            </b-form-group>
            <b-form-group label="Photo">
              <b-form-file
                v-model="selected_file.merchant_photo_url"
                :state="Boolean(selected_file.merchant_photo_url)"
                class="mt-2 border-1 p-2"
                accept=".png, .jpg, .jpeg"
                @input="onChangeFile($event, 'merchant_photo_url')"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <b-form-invalid-feedback :state="!v$.form.merchant_photo_url.$invalid" v-if="v$.form.merchant_photo_url.$errors[0]">
                <span> {{ v$.form.merchant_photo_url.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
              <img ref="current_merchant_photo_url" :src="form.current_merchant_photo_url"/>
            </b-form-group>
            <b-form-group label="Set as Top Merchant">
              <el-switch
                active-text="Active"
                inactive-text="Inactive"
                :active-value="1"
                :inactive-value="0"
                v-model="form.merchant_is_top_list"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
            </b-form-group>
            <b-form-group label="Set as Home Page Highlight Merchant">
              <el-switch
                active-text="Active"
                inactive-text="Inactive"
                v-model="form.is_homepage_highlight"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
            </b-form-group>
            <b-form-group label="Set as Highlight Merchant">
              <el-switch
                active-text="Active"
                inactive-text="Inactive"
                v-model="form.is_highlight"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
            </b-form-group>
            <div class="d-flex flex-row float-right">
              <el-button @click="onSubmitMerchant" :loading="loading.modal_merchant" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
              <el-button @click="closeModalMerchant" size="small" class="mr-2">Cancel</el-button>
              <!-- <b-button class="mr-2" size="sm" @click="closeModalGiveVoucher" type="reset" variant="secondary">Cancel</b-button> -->
            </div>
          </b-form>
        </b-modal>
      </div>
    </div>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { quillEditor } from 'vue-quill-editor';
import { cloneDeep } from 'lodash';
import crypto from 'crypto';
// require styles
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import {
  required, requiredIf, numeric, email, minLength, helpers,
} from '@vuelidate/validators';

import { GET_MERCHANTS, CREATE_MERCHANT, UPDATE_MERCHANT } from '@/store/modules/merchants';
import { GET_CATEGORYS } from '@/store/modules/categorys';
import emptyStateImage from '@/assets/images/empty-state.png';
import base64File from '@/library/base64File';
import popupErrorMessages from '@/library/popup-error-messages';
import imageCompress from '@/library/vue-image-compression';
import passwordComplexity from '@/library/password-complexity';
import axios from "axios";


export default {
  name: 'Merchants',
  metaInfo: {
    title: 'Merchants',
  },
  components: {
    quillEditor,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      emptyStateImage,
      merchantList: [],
      loading: {
        modal_merchant: false,
      },
      editorOption: {
        placeholder: 'Input merchant description',
      },
      merchant_id: '',
      filter_merchant_loading: false,
      per_page: 20,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      loader: null,
      search_keyword: '',
      search_by: '',
      search_merchant_keyword: '',
      selected_file: {
        merchant_banner: null,
        merchant_banner_base64: null,
        merchant_photo_url: null,
        merchant_photo_url_base64: null,
      },
      merchant_status: '',
      categoryList: [],
      total_rows_category: 0,
      statusOptions: [
        {
          value: '',
          label: 'All',
        },
        {
          value: 'active',
          label: 'Active',
        },
        {
          value: 'inactive',
          label: 'Inactive',
        },
      ],
      form: {
        merchant_name: '',
        merchant_address: '',
        merchant_pic: '',
        merchant_telp: '',
        merchant_description: '',
        merchant_photo_url: '',
        merchant_banner: '',
        merchant_logo: '',
        merchant_category_id: '',
        is_highlight: false,
        is_homepage_highlight: false,
        is_registered_merchant_app: false,
        merchant_is_top_list: 0,
        merchant_pic_telp: '',
        merchant_pic_email: '',
        merchant_pic_password: '',
        merchant_margin_fee_amount: 0,
      },
      modalTitle: 'Create new Merchant',
      showModalMerchant: false,
    };
  },
  validations() {
    return {
      form: {
        merchant_name: { required },
        merchant_address: { required },
        merchant_pic: { required },
        merchant_telp: { required },
        merchant_description: { required },
        merchant_photo_url: { required },
        merchant_category_id: { required },
        merchant_banner: { required },
        merchant_pic_telp: {
          required: requiredIf(this.form.is_registered_merchant_app),
          numeric,
        },
        merchant_pic_email: {
          required: requiredIf(this.form.is_registered_merchant_app),
          email,
        },
        merchant_pic_password: {
          required: requiredIf(this.form.is_registered_merchant_app),
          minLength: minLength(8),
          complexity: helpers.withMessage(() => 'Password should have at least 1 uppercase, 1 lowercase, 1 number and 1 special character', passwordComplexity),
        },
        merchant_margin_fee_amount: {
          numeric,
          required: requiredIf(this.form.is_registered_merchant_app),
        },
      },
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getList();
    loader.hide();
  },
  methods: {
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.page = 1;
      this.search_by = 'merchant_name';
      this.getList();
    },
    async getList() {
      this.isLoading = true;
      await this.$store.dispatch(GET_MERCHANTS, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        search_keyword: this.search_keyword,
        status_filter: this.merchant_status,
        is_registered_merchant_app: false,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.merchants;
      this.total_rows = count || 0;
      this.list = rows.map((v) => {
        v.merchant_app_status_str = v.is_registered_merchant_app ? 'Registered' : 'Not Registered';
        v.merchant_app_status_color = v.is_registered_merchant_app ? 'tag-primary' : 'tag-default';
        return v;
      });
      this.isLoading = false;
    },
    clickEditMerchant(item) {
      this.modalType = 'edit';
      this.modalTitle = 'Edit Merchant';
      this.showModalMerchant = true;
      this.getCategoryList();
      this.form = cloneDeep(item);
      this.form.current_merchant_banner = item.merchant_banner;
      this.form.current_merchant_photo_url = item.merchant_photo_url;
      this.v$.form.$touch();
    },
    clikCreateMerchant() {
      this.modalType = 'add';
      this.modalTitle = 'Create New Merchant';
      this.showModalMerchant = true;
      this.getCategoryList();
      this.v$.form.$touch();
    },
    closeModalMerchant() {
      this.showModalMerchant = false;
      this.form = {
        merchant_name: '',
        merchant_address: '',
        merchant_pic: '',
        merchant_telp: '',
        merchant_description: '',
        merchant_photo_url: '',
        merchant_banner: '',
        merchant_logo: '',
        merchant_category_id: '',
        is_highlight: false,
        is_homepage_highlight: false,
        merchant_is_top_list: 0,
      };
    },
    onSubmitMerchant() {
      this.v$.form.$touch();
      if (this.v$.form.$error) {
        return;
      }
      let msg = 'You will create new merchant. Continue?';
      if (this.modalType === 'edit') {
        msg = 'You will update this merchant data. Continue?';
      }
      this.$confirm(msg, 'Confirmation', {
        type: 'info',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            if (this.modalType === 'add') {
              this.createMerchant();
            } else if (this.modalType === 'edit') {
              this.updateMerchant();
            }
          }
          done();
        },
      }).catch(() => {});
    },
    async createMerchant() {
      this.loading.modal_merchant = true;
      const category = this.categoryList.find((v) => v.category_id === this.form.merchant_category_id);
      this.form.merchant_category_str = category?.category_name;
      if (this.form.is_registered_merchant_app) {
        this.form.merchant_margin_fee = this.form.merchant_margin_fee_amount / 100;
      }
      await this.$store.dispatch(CREATE_MERCHANT, this.form)
        .then(() => {
          this.$message({
            title: 'Success',
            type: 'success',
            message: 'Merchant successfully created',
          });
          this.showModalMerchant = false;
        })
        .catch((err) => {
          popupErrorMessages(err.response.data);
        });
      this.getList();
      this.loading.modal_merchant = false;
    },
    async updateMerchant() {
      this.loading.modal_merchant = true;
      await this.$store.dispatch(UPDATE_MERCHANT, {
        id: this.form.merchant_id,
        data: this.form,
      }).then(() => {
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Merchant successfully updated',
        });
        this.getList();
      }).catch((err) => {
        popupErrorMessages(err.response.data);
      });
      this.loading.modal_merchant = false;
      this.showModalMerchant = false;
    },
    async onChangeFile(event, target) {
      if (this.selected_file[target]) {
        const compressed = await imageCompress(this.selected_file[target]);
        this.selected_file[`${target}_base64`] = await base64File(compressed);
        try {
          this.$refs[`current_${target}`].src = this.selected_file[`${target}_base64`];
          this.form[`current_${target}`] = this.selected_file[`${target}_base64`];
          this.form[target] = this.form[`current_${target}`];
        } catch (error) {
          // console.log(error);
        }
      }
    },
    async toggleMerchantStatus(item) {
      let msg = 'Are you sure want to activate this merchant?';
      if (item.merchant_status === 1) {
        msg = 'Are you sure want to inactivate this merchant?';
      }
      this.$confirm(msg, 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(UPDATE_MERCHANT, {
              id: item.merchant_id,
              data: {
                merchant_status: item.merchant_status === 1 ? 0 : 1,
              },
            }).then(() => {
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Merchant successfully updated',
              });
            }).catch((err) => {
              popupErrorMessages(err.response.data);
            });
            instance.confirmButtonLoading = false;
            done();
            this.getList();
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    async getCategoryList() {
      await this.$store.dispatch(GET_CATEGORYS, {
        search_by: 'category_type',
        search_keyword: '1',
      }).catch((err) => console.log(err));
      const { rows, count } = this.$store.getters.categorys;
      this.total_rows_category = count || 0;
      this.categoryList = rows;
    },
    handlerClickGeneratePassword() {
      try {
        this.form.merchant_pic_password = this.generateRandomPassword();
        this.v$.form.$touch();
      } catch (error) {
        console.log(error);
      }
    },
    generateRandomPassword() {
      const wishlist = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      const wishlist_number = '0123456789';
      const wishlist_symbol = '~!@()#$';
      // const length = 10;
      const normal_char = Array.from(crypto.randomFillSync(new Uint8Array(8))).map((x) => wishlist[x % wishlist.length]).join('');
      const number_char = Array.from(crypto.randomFillSync(new Uint8Array(1))).map((x) => wishlist_number[x % wishlist_number.length]).join('');
      const symbol_char = Array.from(crypto.randomFillSync(new Uint8Array(1))).map((x) => wishlist_symbol[x % wishlist_symbol.length]).join('');
      return normal_char + number_char + symbol_char;
    },
    async exportCSV() {
      try {
        const searchData = {
          search_by: this.search_by,
          search_keyword: this.search_keyword,
          page: 1,
          per_page: 100,
        };

        const headers = [
          "Name",
          "PIC",
          "Telp",
          "Status",
          "Address",
        ];

        let allData = [];

        while (true) {
          const response = await axios.get("/api/merchants", {
            responseType: "blob",
            params: searchData,
          });

          if (!response.data) {
            console.error("response.data is undefined...");
            break;
          }

          const merchantsBlob = response.data;
          const merchantsData = await new Response(merchantsBlob).json();

          const currentData = merchantsData.data.rows.map((merchant) => [
            merchant.merchant_name,
            merchant.merchant_pic,
            merchant.merchant_telp,
            merchant.merchant_status === 1 ? "Active" : "Inactive",
            `"${merchant.merchant_address.replace(/"/g, '""')}"`,
            
          ]);

          allData = allData.concat(currentData);

          if (currentData.length < searchData.per_page) {
            break;
          }
          searchData.page++;
        }

        const csvContent = [headers, ...allData]
          .map((row) => row.join(","))
          .join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "exported_merchants.csv";

        link.click();

        // Clean up
        window.URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error("ERROR", error);
        this.$message({
          title: "Export Error",
          type: "error",
          message: "Error. Please check the console for details.",
        });
      }
    },
  },
};
</script>
